import {Howl, Howler} from 'howler';
const $ = require('jquery');

const audios = [
    {
        url: '/assets/audios/happy_together.mp3',
        coverImg: 'new-animation-1_3.gif',
        gridImg: 'icon1.svg',
        coverTitle: 'Because you had us at hello.',
        title: 'Happy Together',
        author: 'The Turtles',
        color: '#0079A6'
    },
    {
        url: '/assets/audios/move_your_feet.mp3',
        coverImg: 'new-animation-2.gif',
        gridImg: 'icon2.svg',
        coverTitle: 'Because we believe united is the way forward.',
        title: 'Move Your Feet',
        author: 'Junior Senior',
        color: '#9D1EF7'
    },
    {
        url: '/assets/audios/dog_days_are_over.mp3',
        coverImg: 'new-animation-3.gif',
        gridImg: 'icon3.svg',
        coverTitle: 'Because the dog days of August will be behind us when we kick off in September!',
        title: 'Dog Days Are Over',
        author: 'Florence & the Machine',
        color: '#7AB102'
    },
    {
        url: '/assets/audios/here_comes_the_sun.mp3',
        coverImg: 'new-animation-4.gif',
        gridImg: 'icon4.svg',
        coverTitle: 'Because CX makes everything all RIGHT.',
        title: 'Here Comes The Sun',
        author: 'The Beatles',
        color: '#E76B00'
    },
    {
        url: '/assets/audios/cant_stop_the_feeling.mp3',
        coverImg: 'new-animation-5.gif',
        gridImg: 'icon5.svg',
        coverTitle: 'Because creating growth for OneDigital makes us want to dance, dance, dance.',
        title: 'Can\'t Stop The Feeling',
        author: 'Justin Timberlake - Trolls Soundtrack',
        color: '#9D1EF7'
    },
    {
        url: '/assets/audios/shake_it_off.mp3',
        coverImg: 'new-animation-6.gif',
        gridImg: 'icon6.svg',
        coverTitle: 'Because positive change, change, change takes dedication, experience and vision.',
        title: 'Shake It Off',
        author: 'Taylor Swift',
        color: '#05A6CC'
    },
    {
        url: '/assets/audios/we_will_rock_you.mp3',
        coverImg: 'new-animation-7.gif',
        gridImg: 'icon7.svg',
        coverTitle: 'Because who doesn\'t love Queen!?! And because we\'re confident we will rock your goals.',
        title: 'We Will Rock You',
        author: 'Queen',
        color: '#0079A6'
    },
    {
        url: '/assets/audios/everything_is_awesome.mp3',
        coverImg: 'new-animation-8.gif',
        gridImg: 'icon8.svg',
        coverTitle: 'Because everything is awesome when you\'re building a dream as part of a team.',
        title: 'Everything is Awesome',
        author: 'The LEGO Movie - Tegan and Sara feat. The Lonely Island',
        color: '#7AB102'
    },
];

const audiosHtmlTemplate = `
<div class="col-6 col-md-3">
    <div class="d-flex flex-column audios" style="background-color: {{color}};">
        <div class="icon">{{cover}}</div>
        <a href="#" class="btn home-play-btn btn-outline-light btn-block mt-3 d-flex align-items-center" data-open-player="{{index}}">
            <span class="w-100">{{title}}</span>
            <i class="fas fa-play"></i>
        </a>
    </div>
</div>`;
const $audiosList = $('[data-audios-list]');

audios.forEach((audio, index) => {
    let html = audiosHtmlTemplate.replace('{{cover}}', `<img src="/assets/img/${audio.gridImg}" />`);
    html = html.replace('{{index}}', index);
    html = html.replace('{{color}}', audio.color);
    html = html.replace('{{title}}', audio.title);
    $audiosList.append(html);
});

const $playerDialog = $('[data-audio-dialog]');
const $timeStart = $('[data-time-start]');
const $timeEnd = $('[data-time-end]');
const $controlPlay = $('[data-control-play]');
const $controlNext = $('[data-control-next]');
const $controlPrev = $('[data-control-prev]');
const $playerProgress = $('[data-player-progress]');
const $playerProgressParent = $('[data-player-progress-parent]');
const $volumeInd = $('[data-volume-ind]');
const $volumeRange = $('[data-volume-range]');
let setIntervalId = null;
let currentSound = null;
let currentIndex = 0;

$audiosList.on('click', '[data-open-player]', function () {
    $playerDialog.modal('show');
    currentIndex = parseInt($(this).data('open-player'));
    const audio = audios[currentIndex];
    // Init Player Dialog
    initPlayerDialog(audio);
    currentSound = initSound(audio);
    // Play sound
    playPauseAudio(currentSound, true);

    const volume = parseInt($volumeRange.val());
    setPlayerVolume(volume);
});

function initPlayerDialog(audio) {
    // Color
    $playerDialog.find('.modal-content').css({backgroundColor: audio.color});
    // Cover icon
    $playerDialog.find('.player-cover img').attr('src', `/assets/img/${audio.coverImg}`);
    // Cover title
    $playerDialog.find('.player-cover p').html(audio.coverTitle);
    // Audio title & author
    $playerDialog.find('.player-title-author .title').text(audio.title);
    $playerDialog.find('.player-title-author .author').text(`by ${audio.author}`);

    // Progress
    $playerProgress.width(0);
    // Timers
    $timeStart.text(secondsToHuman(0));
    $timeEnd.text(secondsToHuman(0));

    // Play Pause icon
    $controlPlay.find('.far').removeClass('fa-pause-circle').addClass('fa-play-circle');
}

function initSound(audio) {
    clearUpdateInterval();
    clearAllEvents(currentSound);

    const sound = new Howl({
        src: [audio.url],
        autoplay: false
    });

    sound.on('play', () => {
        setIntervalId = setInterval(() => {
            updatePlayerTimePosition(sound);
        }, 300);
    });

    sound.on('end', playNext);

    return sound;
}

function playPauseAudio(sound, forcePlay = null) {
    let play;

    if (forcePlay === true && !sound.playing()) {
        play = true;
    }

    if (forcePlay === false && sound.playing()) {
        play = false;
    }

    // If play is not set
    if (typeof play === 'undefined') {
        play = !sound.playing();
    }

    // Play or pause the sound
    if (play) {
        sound.play();
        $controlPlay.find('.far').removeClass('fa-play-circle').addClass('fa-pause-circle');
    } else {
        sound.pause();
        clearUpdateInterval();
        $controlPlay.find('.far').removeClass('fa-pause-circle').addClass('fa-play-circle');
    }
}

function toggleMute(sound) {
    const shouldMute = sound.mute() === false;
    sound.mute(shouldMute);

    if (shouldMute) {
        $volumeInd.find('.fas').removeClass('fa-volume-up fa-volume-down fa-volume-off').addClass('fa-volume-mute');
    } else {
        const volume = parseInt($volumeRange.val());
        setPlayerVolume(volume);
    }
}

function clearUpdateInterval() {
    if (setIntervalId) {
        clearInterval(setIntervalId);
    }
}

function clearAllEvents(sound) {
    if (sound) {
        sound.off('play');
        sound.off('end');
    }
}

function updatePlayerTimePosition(sound) {
    if (sound.playing()) {
        const seconds = sound.seek();
        const duration = sound.duration();
        let progress = (seconds/duration)*100;

        $timeStart.text(secondsToHuman(seconds));
        $timeEnd.text(secondsToHuman(duration));
        $playerProgress.width(progress + '%');
    }
}

function setPlayerVolume(volume) {
    Howler.volume(volume/100);

    if (volume > 60) {
        $volumeInd.find('.fas').removeClass('fa-volume-down fa-volume-off fa-volume-mute').addClass('fa-volume-up');
    } else if (volume > 20 && volume <= 60) {
        $volumeInd.find('.fas').removeClass('fa-volume-up fa-volume-off fa-volume-mute').addClass('fa-volume-down');
    } else {
        $volumeInd.find('.fas').removeClass('fa-volume-up fa-volume-down fa-volume-mute').addClass('fa-volume-off');
    }
}

function secondsToHuman(seconds) {
    let minutes = Math.floor(seconds / 60) + '';
    minutes = minutes.padStart(2, '0');
    seconds = Math.floor(seconds % 60) + '';
    seconds = seconds.padStart(2, '0');

    return minutes + ':' + seconds;
}

function playNext() {
    if (currentIndex >= (audios.length -1)) {
        currentIndex = 0;
    } else {
        currentIndex++;
    }
    const audio = audios[currentIndex];

    // Stop Player
    Howler.stop();
    // Init Player Dialog
    initPlayerDialog(audio);
    currentSound = initSound(audio);

    // Play sound
    playPauseAudio(currentSound, true);
}

function playPrev() {
    if (currentIndex <= 0) {
        currentIndex = audios.length - 1;
    } else {
        currentIndex--;
    }
    const audio = audios[currentIndex];

    // Stop Player
    Howler.stop();
    // Init Player Dialog
    initPlayerDialog(audio);
    currentSound = initSound(audio);

    // Play sound
    playPauseAudio(currentSound, true);
}

$playerDialog.on('hide.bs.modal	', () => {
    Howler.stop();
    clearUpdateInterval();

    clearAllEvents(currentSound);
});

$volumeRange.on('change', function() {
    const val = parseInt($(this).val());
    setPlayerVolume(val);
});

$volumeInd.on('click', function() {
    if (currentSound) {
        toggleMute(currentSound);
    }
});

$controlPlay.on('click', () => {
    if (currentSound) {
        playPauseAudio(currentSound);
    }
});

$controlNext.on('click', playNext);

$controlPrev.on('click', playPrev);

$playerProgressParent.on('click', (e) => {
    if (currentSound) {
        const progress = e.offsetX / $playerProgressParent.width();
        const duration = currentSound.duration();
        const seconds = progress * duration;
        currentSound.seek(seconds);
        $playerProgress.width((seconds/duration)*100 + '%');
    }
})
