const $ = require('jquery');

$password = $('[data-password]');
$submit = $('[data-submit]');
$form = $('[data-login-form]');

$form.on('submit', (e) => {
    e.preventDefault();
    login($password.val());
});

$password.on('input', (e) => {
    $submit.toggleClass('active', e.currentTarget.value.length > 0);
});

function login(password) {
    if (password === 'RockOn') {
        window.location.href = '/home.html';
    }
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// Check Query param
let password = getParameterByName('password')
if (password) {
    login(password);
}
